import { axiosConToken } from "../helpers/axios";
import {
  messageLoading,
  messageSuccessLoading,
  validaMessagesErrors,
} from "../helpers/messageConfig";
import { types } from "../types/typesCompra";

const routes = {
  compra: "compras/comprobantecompra/",
};

export const comprobantecompraStartLoad = (
  filter_type = null,
  query = null
) => {
  return async (dispatch) => {
    try {
      const resp = await axiosConToken.get(routes.compra, {
        params: {
          filter_type,
          query,
        },
      });
      const data = resp.data;
      dispatch(comprobantecompraLoaded(data));
    } catch (error) {
      validaMessagesErrors(error);
    }
  };
};
export const comprobantecompraStartAdd = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.post(routes.compra, data);
      const { msg, comprobante } = resp.data;
      dispatch(comprobantecompraAdded(comprobante));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const comprobantecompraStartUpdate = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.put(`${routes.compra}${data.id}/`, data);
      const { msg, comprobante } = resp.data;
      dispatch(comprobantecompraUpdated(comprobante));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
export const comprobantecompraStartDelete = (data) => {
  return async (dispatch) => {
    const msgload = messageLoading("Guardando...");
    try {
      const resp = await axiosConToken.delete(`${routes.compra}${data.id}/`);
      const { msg } = resp.data;
      dispatch(comprobantecompraDeleted(data));
      messageSuccessLoading(msgload, msg);
    } catch (error) {
      validaMessagesErrors(error, msgload);
    }
  };
};
const comprobantecompraLoaded = (comprobantes) => ({
  type: types.comprobantecompraLoaded,
  payload: comprobantes,
});
const comprobantecompraAdded = (comprobante) => ({
  type: types.comprobantecompraAdded,
  payload: comprobante,
});
const comprobantecompraUpdated = (comprobante) => ({
  type: types.comprobantecompraUpdated,
  payload: comprobante,
});
const comprobantecompraDeleted = (comprobante) => ({
  type: types.comprobantecompraDeleted,
  payload: comprobante,
});
export const showModalComprobanteOptionPrint = (status = true) => ({
  type: types.comprobanteShowModalOptionPrint,
  payload: status,
});
export const comprobantecompraSetActive = (comprobante) => ({
  type: types.comprobantecompraSetActive,
  payload: comprobante,
});
export const comprobantecompraClearActive = () => ({
  type: types.comprobantecompraClearActive,
});
export const detallecomprobantecompraSetActive = (detalle) => ({
  type: types.detallecomprobantecompraSetActive,
  payload: detalle,
});
export const detallecomprobantecompraClearActive = () => ({
  type: types.detallecomprobantecompraClearActive,
});
export const showFormComprobanteCompra = (visible = true) => ({
  type: types.comprobantecompraShowForm,
  payload: visible,
});

//TODO: Implement extra functions
// ASYNC FUNCTIONS
export const get_tipocomprobantes_ingreso = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.compra}get_tipocomprobantes_ingreso/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};

export const search_items_by_param = async (param) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.compra}search_items_by_param/`,
      { params: { param } }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_almacenes = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`${routes.compra}get_almacenes/`);
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_condicionespago = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.compra}get_condicionespago/`
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_unidadesmedida = async () => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`${routes.compra}get_unidadesmedida/`);
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};

export const get_mediospago = async (condicionpago) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(`${routes.compra}get_mediospago/`, {
      params: { condicionpago },
    });
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
export const get_pdf_comprobantecompra = async (comprobante, size_type) => {
  let datos = [];
  try {
    const resp = await axiosConToken.get(
      `${routes.compra}get_pdf_comprobantecompra/`,
      {
        responseType: "blob",
        params: { comprobante, size_type },
      }
    );
    datos = resp.data;
  } catch (error) {
    validaMessagesErrors(error);
  }
  return datos;
};
