import React from "react";
import { Alert, Col, Row, Switch, Form, Input, InputNumber } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { ProductoImageUpload } from "./reusable/ProductoImageUpload";
import { TiposAfectionProducto } from "./reusable/TiposAfectionProducto";
import { CategoriaProductoSelect } from "./reusable/CategoriaProductoSelect";
import { SubCategoriaProductoSelect } from "./reusable/SubCategoriaProductoSelect";
import { UnidadMedidaSelect } from "./reusable/UnidadMedidaSelect";
import { TiposItemSelect } from "./reusable/TiposItemSelect";
import { FormItemHiddenUi } from "../ui/FormItemHiddenUi";
import { SwitchUi } from "../ui/SwitchUi";
import { MonedaSelect } from "./reusable/MonedaSelect";
import { RutaProduccionSelect } from "./reusable/RutaProduccionSelect";

export const ProductoGeneralTabContent = () => {
  const form = Form.useFormInstance();
  const { TextArea } = Input;
  const categoriaitem = Form.useWatch("categoriaitem", form);
  const esinventariado = Form.useWatch("esinventariado", form);
  const esfabricado = Form.useWatch("esfabricado", form);
  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col sm={24} md={24} lg={18}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} md={20}>
              <FormItemHiddenUi name="id" />
              <Form.Item
                name="nombre"
                label="Nombre"
                rules={[
                  { required: true, message: "El nombre es obligatorio" },
                ]}
              >
                <Input placeholder="Ingrese nombre del producto" />
              </Form.Item>
            </Col>
            <Col xs={24} md={20}>
              <Form.Item
                name="descripcion"
                label="Descripción"
                rules={[
                  { required: true, message: "La descripción es obligatorio" },
                ]}
              >
                <TextArea
                  placeholder="Ingrese descripción del producto"
                  cols={4}
                ></TextArea>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} md={8} lg={6}>
              <Form.Item
                label="Precio venta"
                name="precioventa"
                rules={[
                  {
                    required: true,
                    message: "El precio de venta es obligatorio",
                    type: "number",
                  },
                  {
                    type: "number",
                    min: 1,
                    message: "Ingrese el precio de venta mayor a 0",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Ingrese precio de venta"
                  style={{ width: "100%" }}
                  min={1}
                  precision={2}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <Form.Item
                label="Precio compra"
                name="costo"
                rules={[
                  {
                    required: true,
                    message: "El precio de compra es obligatorio",
                    type: "number",
                  },
                  {
                    type: "number",
                    min: 1,
                    message: "Ingrese el precio de compra mayor a 0",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Ingrese precio de compra"
                  style={{ width: "100%" }}
                  min={1}
                  precision={2}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <Form.Item
                label="Moneda"
                name="moneda"
                rules={[
                  {
                    required: true,
                    message: "El moneda es obligatoria",
                  },
                ]}
              >
                <MonedaSelect placeholder="Seleccione moneda" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} md={8} lg={6}>
              <Form.Item
                label="Stock Minimo"
                name="stockminimo"
                rules={[
                  {
                    required: true,
                    message: "El stock minimo es obligatorio",
                    type: "number",
                  },
                  {
                    type: "number",
                    min: 0,
                    message: "Ingrese el stock minimo mayor a 0",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Ingrese stock minimo"
                  style={{ width: "100%" }}
                  min={0}
                  precision={2}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <Form.Item
                label="Unidad medida"
                name="unidadmedida"
                rules={[
                  {
                    required: true,
                    message: "La unidad de medida es obligatoria",
                  },
                ]}
              >
                <UnidadMedidaSelect placeholder="[-Seleccione-]" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                label="Tipo Afectación"
                name="tipoafectacionigv"
                rules={[
                  {
                    required: true,
                    message: "El tipo de afectación es obligatorio",
                  },
                ]}
              >
                <TiposAfectionProducto placeholder="[-Seleccione-]" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col xs={24} md={8} lg={6}>
              <Form.Item
                label="Categoria"
                name="categoriaitem"
                rules={[
                  {
                    required: true,
                    message: "La categoria es obligatoria",
                  },
                ]}
              >
                <CategoriaProductoSelect
                  placeholder="[-Seleccione-]"
                  onChange={(value) => {
                    form.setFieldValue("subcategoriaitem", null);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={8} lg={6}>
              <Form.Item
                label="SubCategoria"
                name="subcategoriaitem"
                rules={[
                  {
                    required: true,
                    message: "La subcategoria es obligatoria",
                  },
                ]}
              >
                <SubCategoriaProductoSelect
                  placeholder="[-Seleccione-]"
                  categoria={categoriaitem || 0}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Form.Item
                label="Tipo item"
                name="tipoitem"
                rules={[
                  {
                    required: true,
                    message: "El tipo de item es obligatorio",
                  },
                ]}
              >
                <TiposItemSelect placeholder="[-Seleccione-]" />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col sm={24} md={24} lg={6}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item name="imagen">
                <ProductoImageUpload name="imagen" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <Form.Item label="Referencia(SKU)" name="sku">
                <InputNumber
                  placeholder="Referencia(SKU)"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24 }}>
        <Col xs={12} sm={12} md={12} lg={5}>
          <Form.Item
            label="¿El item se elabora en la empresa?"
            name="esfabricado"
            valuePropName="checked"
          >
            <SwitchUi
              onChange={(value) => {
                form.setFieldValue("rutaproduccion", null);
              }}
            />
          </Form.Item>
        </Col>
        {esfabricado && (
          <Col xs={12} sm={12} md={12} lg={5}>
            <Form.Item label="Ruta producción" name="rutaproduccion">
              <RutaProduccionSelect placeholder="[-Seleccione-]" />
            </Form.Item>
          </Col>
        )}
        <Col xs={12} sm={12} md={8} lg={6} xl={4}>
          <Form.Item
            label="¿Disponible para la venta?"
            name="sevende"
            valuePropName="checked"
          >
            <SwitchUi
            // onChange={(value) => {
            //   form.setFieldValue("rutaproduccion", null);
            // }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Alert
        message="¿Es inventariado?"
        showIcon
        description="Al producto se le hará un seguimiento detallado de las entradas y salidas de existencias"
        type="info"
        action={
          <Form.Item name="esinventariado" valuePropName="checked">
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
            />
          </Form.Item>
        }
      />
      {esinventariado && (
        <>
          <Row gutter={[16, 16]} className="mt-2">
            <Col xs={12} md={8}>
              <Form.Item
                name="manejalotes"
                label="¿Maneja lotes?"
                valuePropName="checked"
              >
                <SwitchUi />
              </Form.Item>
            </Col>
            <Col xs={12} md={8}>
              <Form.Item
                name="manejaseries"
                label="¿Maneja series?"
                valuePropName="checked"
              >
                <SwitchUi />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={[16, 16]} className="mt-2">
            <Col xs={12} md={8}>
              <Form.Item
                name="almaceninicial"
                label="Almacén"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 12 }}
              >
                <AlmacenEmpleadoSelect
                  employee={activeEmpleado?.id}
                  placeholder="Seleccione almacen inicial"
                />
              </Form.Item>
            </Col>
            <Col xs={12} md={8}>
              <Form.Item
                name="stockinicial"
                label="Stock inicial"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 10 }}
              >
                <InputNumber
                  placeholder="Ingrese stock inicial"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row> */}
        </>
      )}
    </>
  );
};
