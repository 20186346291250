import { Descriptions, InputNumber, Form, Input } from "antd";
import React from "react";
import { ButtonUi } from "../../../ui/ButtonUi";
import { TipoButtonConstants } from "../../../../types/typesConstants";
import { useSelector } from "react-redux";
import { parseNumeric } from "../../../../helpers/utils";
import { fn_CalculoTotales_Item } from "../../../../helpers/functions";
const Style_ItemDescription = {
  paddingBottom: "2px",
  paddingInlineEnd: "4px",
};
const Style_Input = {
  fontSize: "11px",
  marginBottom: "0px",
};
export const PosCarritoContentItem = ({ field, handleAdd, handleRemove }) => {
  const form = Form.useFormInstance();
  const { activeEmpleado } = useSelector((state) => state.auth);
  const { setFieldValue, getFieldValue } = form;
  const { manejalotes, manejaseries } = getFieldValue([
    "detallecomprobante",
    field.name,
  ]);
  const readOnlyFieldLotesOrSeries = !manejalotes && !manejaseries;
  const detallecomprobante = Form.useWatch("detallecomprobante", form);
  const onChangeCantidad = (e, field) => {
    const item = detallecomprobante[field?.name];
    const porcentajeigv = parseNumeric(activeEmpleado?.empresa?.porcentajeigv);
    const {
      valorunitario,
      valorigvunitario,
      valorigv,
      valorventabruto,
      valorventa,
      totalventa,
    } = fn_CalculoTotales_Item({
      preciounitario: item?.preciounitario,
      cantidad: e,
      descuentos: item?.descuentos,
      porcentajeigv,
    });
    setFieldValue(
      ["detallecomprobante", field.name, "valorunitario"],
      valorunitario
    );
    setFieldValue(
      ["detallecomprobante", field.name, "valorigvunitario"],
      valorigvunitario
    );
    setFieldValue(["detallecomprobante", field.name, "valorigv"], valorigv);
    setFieldValue(
      ["detallecomprobante", field.name, "valorventabruto"],
      valorventabruto
    );
    setFieldValue(["detallecomprobante", field.name, "valorventa"], valorventa);
    setFieldValue(["detallecomprobante", field.name, "totalventa"], totalventa);
  };
  const OnChangePrecioVenta = (e, field) => {
    const item = detallecomprobante[field.name];
    const porcentajeigv = parseNumeric(activeEmpleado?.empresa?.porcentajeigv);
    const {
      valorunitario,
      valorigvunitario,
      valorigv,
      valorventabruto,
      valorventa,
      totalventa,
    } = fn_CalculoTotales_Item({
      preciounitario: e,
      cantidad: item?.cantidad,
      descuentos: item?.descuentos,
      porcentajeigv,
    });
    setFieldValue(
      ["detallecomprobante", field.name, "valorunitario"],
      valorunitario
    );
    setFieldValue(
      ["detallecomprobante", field.name, "valorigvunitario"],
      valorigvunitario
    );
    setFieldValue(["detallecomprobante", field.name, "valorigv"], valorigv);
    setFieldValue(
      ["detallecomprobante", field.name, "valorventabruto"],
      valorventabruto
    );
    setFieldValue(["detallecomprobante", field.name, "valorventa"], valorventa);
    setFieldValue(["detallecomprobante", field.name, "totalventa"], totalventa);
  };

  return (
    <>
      <Descriptions column={6}>
        <Descriptions.Item
          style={{ ...Style_ItemDescription, width: "10%" }}
          className="poscarritocontent__item"
        >
          <Form.Item
            {...field}
            name={[field.name, "prefijounidadmedida"]}
            style={{ marginBottom: 0 }}
          >
            <Input
              size="small"
              variant="borderless"
              readOnly={true}
              style={Style_Input}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          style={{ ...Style_ItemDescription, width: "15%" }}
          className="poscarritocontent__item"
        >
          <Form.Item
            {...field}
            name={[field.name, "cantidad"]}
            rules={[
              { required: true, message: "La cantidad es obligatoria" },
              {
                type: "number",
                message: "Ingrese un número",
              },
            ]}
            style={{ marginBottom: 0 }}
          >
            <InputNumber
              size="small"
              style={Style_Input}
              onChange={(value) => onChangeCantidad(value, field)}
              min={1}
              precision={2}
              readOnly={!readOnlyFieldLotesOrSeries}
              variant={readOnlyFieldLotesOrSeries ? "outlined" : "borderless"}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          style={{ ...Style_ItemDescription, width: "40%" }}
          contentStyle={{ width: "100%", display: "inline" }}
          className="poscarritocontent__item"
        >
          <Form.Item
            {...field}
            name={[field.name, "nombre"]}
            style={{ marginBottom: 0 }}
          >
            <Input
              size="small"
              variant="borderless"
              readOnly={true}
              style={{ fontSize: "8px" }}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          style={{ ...Style_ItemDescription, width: "15%" }}
          className="poscarritocontent__item"
        >
          <Form.Item
            {...field}
            name={[field.name, "preciounitario"]}
            rules={[
              { required: true, message: "El precio de venta es obligatorio" },
              {
                type: "number",
                message: "Ingrese un número",
              },
            ]}
          >
            <InputNumber
              size="small"
              style={{ ...Style_Input, width: "100%" }}
              onChange={(value) => OnChangePrecioVenta(value, field)}
              precision={2}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          style={{ ...Style_ItemDescription }}
          className="poscarritocontent__item"
        >
          <Form.Item
            {...field}
            name={[field.name, "totalventa"]}
            rules={[
              { required: true, message: "El total es obligatorio" },
              {
                type: "number",
                message: "Ingrese un número",
              },
            ]}
          >
            <InputNumber
              size="small"
              variant="borderless"
              readOnly={true}
              precision={2}
              style={{ ...Style_Input, width: "100%" }}
            />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item
          style={{
            paddingBottom: "2px",
            paddingTop: "5px",
            paddingLeft: "5px",
          }}
          className="poscarritocontent__item"
        >
          <ButtonUi
            typeUi={TipoButtonConstants.EliminarTable}
            displayText={false}
            onClick={() => handleRemove(field.name)}
            size="small"
          />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};
